.product-listing-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px 40px;

  .card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    font-family: "Roboto";
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}
