.navbar {
  height: 65px;
  box-shadow: 0 3px 6px var(--shadow-color);
  background-color: var(--background-color);
  font-family: var(--text-family-roboto);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navlogo__links {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
    font-family: var(--text-family-martel);
    
    img {
      width: 50px;
    }

    p {
        padding-left: 5px;
        color: var(--nav-primaryText);

        span {
            color: var(--nav-secondaryText)
        }
    }
  }

  .nav__links {
    a {
        margin-left: 1em;
        color: var(--primary-color);
        letter-spacing: 1px;
        font-size: .9em;
        font-family: var(--text-family-roboto);
        
        &::before {
            content: '';
            display: inline-block;
            height: 2px;
            width: 100%;
            background-color: var(--primary-color);
            position: absolute;
            bottom: -5px;
            left: 0;
            transform: scaleX(0);
            transition: getAnimationDuration(.2s) ease-in-out;
        }

        &:hover {
            &::before {
                transform: scaleX(1);
            }
        }

        &:active {
            color: var(--primary-color);
        }
    }
  }

  .sidebar_btn {
    height: 28px;
    width: 30px;
    position: relative;
    display: none;
    cursor: pointer;

    .bar{
        border-radius: 4px;
        height:4px;
        width:100%;
        background-color: var(--text-color);
        position:absolute;
        transition: transform getAnimationDuration(.5s);

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 50%;
            transform-origin: right;
            transform: scaleX(.8) translateY(-50%)
        }

        &:nth-child(3) {
            top: 100%;
            transform-origin: right;
            transform: scaleX(1.2) translateY(-100%)

        }
    }
    &.active { 
        .bar{
            transform-origin: center;
            top: 50%;
            &:nth-child(1) {
                transform: translate(0, -50%) scale(1) rotate(45deg);
            }
            &:nth-child(2) {
                transform: scale(0) translateY(50%);
            }
            &:nth-child(3) {
                transform: translate(0, -50%) scale(1) rotate(-45deg);
            }
        }
    }
  }
}

@media screen and (max-width: 768px) {
    .navbar {
        .nav__links {
            display: none;
        }

        .sidebar_btn {
            display: block;
        }
    }
}
