@import url("https://fonts.googleapis.com/css2?family=Martel:wght@700&family=Noto+Sans&family=Roboto:wght@500&display=swap");

:root {
  --background-color: #fff;
  --background-light: #fff;
  --text-family-roboto: "Roboto";
  --text-family-martel: "Martel";
  --text-family-noto: "Noto Sans";
  --shadow-color: rgba(0, 0, 0, 0.2);
  --primary-color: #386640;
  --text-color: #0a0a0a;
  --text-light: #575757;
  --nav-primaryText: #244e3a;
  --nav-secondaryText: #9ac065;
  --font-size: 16px;
  --animation-speed: 1;
}

@function getAnimationDuration($duration: number) {
  @return calc($duration * var(--animation-speed));
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

// F6F9F6

body {
  font-size: var(--font-size);
  font-size: "Roboto", "Noto Sans", "Martel", sans-serif;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.box {
  margin: 15px 0;
  padding: 5px 10px;
  transition: 0.5s;
}

.main {
  padding-block: 3vw;
}

.container {
  padding-inline: 4vw;
  // min-height: 100vh;
  //   display: flex;
  //   flex-direction: column;
}
