.product-details-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px;

  img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #0077ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  button:hover {
    background-color: #005299;
  }
}
