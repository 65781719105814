@import "../../styles/partials/animation";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 500px;
  background-color: var(--background-color);
  box-shadow: 0 0 0 1200px rgba(0, 0, 0, 0.2), 3px 0 5px var(--shadow-color);
  animation: getAnimationDuration(0.3s) slideInLeft;

  .sidebar_link {
    display: block;
    padding: 0.5em 0.1em;
    border-left: 0.4em solid transparent;
    margin-bottom: 0.1em;
    color: var(--primary-color);
    font-size: 1.2em;
    font-family: var(--text-family-noto);
    // transform: translateX(-100%);
    animation: getAnimationDuration(0.3s) slideInLeft forwards;
        @for $i from 1 through 4{
            &:nth-child(#{$i}){
                animation-delay: #{$i * .1}s;
            }
        }

    &.active {
      border-left-color: var(--primary-color);
    }
    &:hover {
      border-left-color: var(--primary-color);
      background-color: rgba(0, 0, 0, 0.03);
    }
    svg {
      width: 1.2em !important;
      margin-right: 0.5em;
      margin-bottom: -2px;
    }
  }
}
