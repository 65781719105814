footer {
  margin-top: auto;
  position: relative;
  padding: 50px 0 20px 0;
  background: linear-gradient(
      0deg,
      rgba(56, 102, 65, 0.81),
      rgba(56, 102, 65, 0.81)
    ),
    url("../../images/footer-img.jpg") no-repeat;

  .footer_logo {
    width: 20%;
  }

  @media only screen and (max-width: 1024px) {
    .footer_logo {
      width: 50px;
      margin: auto;
    }
  }

  p {
    font-family: "Noto Sans";
    font-size: 14px;
    line-height: 27px;
    color: var(--background-color);
  }

  @media only screen and (max-width: 1024px) {
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #ffffff;
    text-transform: uppercase;
    list-style: none;
    text-decoration: none !important;
  }

  @media only screen and (max-width: 1024px) {
    .h2 {
      font-size: 15px;
      line-height: 24px;
    }
  }
}

hr {
  width: 100%;
  border: 1px solid #ffffffcc;
  margin-bottom: 20px;
  text-align: center;
}

.footer-center {
  text-align: center;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin: auto;
}

@media only screen and (max-width: 1024px) {
  .footer-center {
    font-size: 12px;
    line-height: 20px;
    margin: auto;
  }
}
