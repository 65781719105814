.section {
  position: relative;
  z-index: 3;
  padding-top: 50px;
  padding-bottom: 50px;

  .section-header {
    margin-bottom: 50px;
    text-align: center;

    .container {
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
    }

    h2 {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 40px;
      margin-bottom: 20px;
      font-family: "Roboto";
    }

    p {
      color: #fff;
      font-family: "Noto Sans";
    }
  }

  .container {
    .row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .contact-info {
        width: 50%;

        .contact-info-item {
          display: flex;
          margin-bottom: 30px;

          .contact-info-icon {
            height: 70px;
            width: 70px;
            background-color: #fff;
            text-align: center;
            border-radius: 50%;

            .contact-info-icon .i {
              font-size: 50px;
              line-height: 70px;
            }
          }

          .contact-info-content {
            margin-left: 20px;
          }

          .contact-info-content h4 {
            color: var(--text-color);
            font-size: 1.4em;
            font-family: "Noto Sans";
            margin-bottom: 5px;
          }

          .contact-info-content p {
            color: var(--text-light);
            font-size: 1em;
            font-family: "Noto Sans";
          }
        }
      }

      .contact-form {
        background-color: #fff;
        padding: 40px;
        width: 45%;
        padding-bottom: 20px;
        padding-top: 20px;

        h2 {
          font-weight: bold;
          font-family: "Roboto";
          font-size: 2em;
          margin-bottom: 10px;
          color: #333;
        }

        .input-box {
          position: relative;
          width: 100%;
          margin-top: 10px;

          input,
          textarea {
            width: 100%;
            padding: 5px 0;
            font-size: 16px;
            margin: 10px 0;
            border: none;
            border-bottom: 2px solid #333;
            outline: none;
            resize: none;
            cursor: pointer;
          }

          span {
            position: absolute;
            left: 0;
            padding: 5px 0;
            font-size: 16px;
            margin: 10px 0;
            pointer-events: none;
            transition: 0.5s;
            color: #666;
          }

          input:focus ~ span,
          textarea:focus ~ span {
            color: var(--primary-color);
            font-size: 12px;
            transform: translateY(-20px);
          }

          input[type="submit"] {
            width: 100%;
            background: var(--primary-color);
            color: #fff;
            border: none;
            cursor: pointer;
            padding: 10px;
            font-size: 18px;
            border: 1px solid var(--primary-color);
            transition: 0.5s;
          }

          input[type="submit"]:hover {
            background: var(--background-color);
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .contact-form {
    width: 100%;
  }
}
