.hero {
  background: #f6f9f6;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  padding: 30px 0 70px 0;

  .hero_primaryText {
    h1 {
      max-width: 1237px;
      font-family: "Martel";
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 76px;
      color: var(--text-color);
      // text-align: left;
    }

    @media only screen and (max-width: 768px) {
      h1 {
        font-size: 18px;
        line-height: 40px;
        margin: auto;
      }
    }
  }

  .hero_secondaryText {
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: var(--text-light);
      text-align: left;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @media only screen and (max-width: 768px) {
      p {
        font-size: 14px;
        line-height: 20px;
        margin-top: auto;
        margin-bottom: 20px;
      }
    }

    .home_Btn {
      // max-width: 165px;
      //   margin-bottom: 30px;
      border: 2px solid var(--primary-color);
      border-radius: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: var(--primary-color);
      text-transform: uppercase;
      text-decoration: none;
      padding: 10px 15px !important;
      text-align: left;
      cursor: pointer;
    }

    @media only screen and (max-width: 1024px) {
      .home_Btn {
        font-size: 14px;
        line-height: 16px;
        padding: 8px 10px !important;
        /* margin-bottom: auto; */
      }
    }
  }
}

.hero_one {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  background: var(--background-color);
  padding: 30px 0 70px 0;

  .hero_one-text {
    flex: 1;
    .title {
      font-family: "Martel";
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 76px;
      color: var(--text-color);
    }

    .info {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: var(--text-light);
      text-align: left;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .btn {
      border: none;
      outline: none;
      background-color: var(--primary-color);
      border-radius: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #f6f9f6;
      text-transform: uppercase;
      text-decoration: none;
      padding: 10px 15px !important;
      text-align: left;
      letter-spacing: 1.5px;
      box-shadow: 0 2px 6px var(--shadow-color);
      cursor: pointer;

      &:hover {
        box-shadow: 0 1px 3px var(--shadow-color);
      }
    }
  }

  .hero_one-img {
    flex: 1;
    text-align: right;

    .block-img {
      max-width: 45%;
      margin-top: 35px;
    }

    @media only screen and (max-width: 1024px) {
      .block-img {
        margin-top: 30px;
        margin-right: auto;
        max-width: 50%;
      }
    }
  }
}

.hero-two {
  background: #f6f9f6;
  padding: 30px 0 70px 0;

  .heroProduct_text {
    h1 {
      font-family: "Martel";
      font-style: normal;
      font-weight: 800;
      font-size: 50px;
      line-height: 81px;
      color: #18251a;
      text-align: center;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 1024px) {
      h1 {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }

  .image-wrapper .home4-img {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
  }

  @media only screen and (max-width: 1024px) {
    .image-wrapper .home4-img {
      width: 50%;
    }
  }

  .image-wrapper p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #18251acc;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 1024px) {
    .image-wrapper p {
      font-size: 12px;
      line-height: 17px;
    }
  }

  .slick-slide {
    text-align: center;
    position: relative;
  }
  .slick-slide:focus {
    outline: none;
  }
  .slick-slide-title {
    text-transform: capitalize;
  }
  .slick-slide-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
      0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
}
